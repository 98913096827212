













































































import ThemeModel from '@/dtos/theme';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
export default Vue.extend({
  components: { SessionWrapper },
  data() {
    return {
      form: {
        rating: 1,
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
  },
  methods: {
    async submitReview() {
      const { rating, message } = this.form;
      if (rating && message && this.activeSession) {
        const sessionId = this.activeSession.id;
        await this.$store.dispatch('viewer/submitReview', {
          rating,
          message,
          sessionId,
        });
        this.$router.push({ name: 'Viewer Session Review Success' });
      }
    },
  },
});
